import React from "react";
import { Fragment } from "react";
import { Colors } from "../../commons/Theme";
import BusinessIconTextItem from "../BusinessIconText/BusinessIconTextItem";
import IconV2, { icons } from "../Icon/IconV2";
import SectionHeader from "../SectionHeader/SectionHeader";
import Spacer from "../Spacer/Spacer";
import {
  StyledInstructionsCard,
  StyledInstructionsItemsContainer,
  StyledInstructionsSubtitle,
  StyledInstructionsItem,
} from "./style";

const Instructions = ({
  id,
  title,
  variant,
  subtitle,
  items,
  arrows = true,
}) => {
  console.log(id)
  return (
    <div id={id}>
      {title &&(<SectionHeader title={title} padding={"0 2rem"}/>)}
      <StyledInstructionsCard variant={variant}>
        <StyledInstructionsSubtitle>{subtitle}</StyledInstructionsSubtitle>
        <StyledInstructionsItemsContainer>
          {items.map((item, index) => (
            <Fragment key={index}>
              <StyledInstructionsItem maxWidth={true}>  
                <BusinessIconTextItem
                  image={item.image}
                  htmlText={item.text}
                  label={item.title}
                  variant={variant}
                />
              </StyledInstructionsItem>
              {((index+1) !== items.length) && (arrows) && (
                <StyledInstructionsItem arrow={true}>
                  <IconV2 icon={icons.arrowRightLong} color={Colors.white} height="20px"/>
                </StyledInstructionsItem>
              )}
            </Fragment>
          ))}
        </StyledInstructionsItemsContainer>
      </StyledInstructionsCard>
    </div>
  );
};

export default Instructions;
