import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

const variantColorsBackground = {
  orange: Colors.yellow,
  orangeLight: Colors.ligthyellow,
  pink: Colors.pinkText,
  green: Colors.greenPrimary,
};

const variantColorsText = {
  orange: Colors.white,
  orangeLight: Colors.black,
  pink: Colors.white,
  green: Colors.white,
};

export const StyledInstructionsCard = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => variantColorsText[props.variant]};
  padding: ${Rem(42)} 0;
  background-color: ${(props) => variantColorsBackground[props.variant]};
 

  @media (${BP.large}) {
    margin: 0 144px;
    border-radius: 20px;
  }

  @media (${BP.tablet}) {
    padding: ${Rem(42)};
    border-radius: 20px;
  }
`;

export const StyledInstructionsSubtitle = styled.h3`
  text-align: center;
  font-size: ${Rem(25)};
  margin-bottom: ${Rem(25)};

  @media (${BP.tablet}) {
    font-size: ${Rem(32)};
  }
`;

export const StyledInstructionsItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (${BP.tablet}) {
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const StyledInstructionsItem = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${(props) =>
    props.maxWidth
    && css`
      max-width: 130px;
    `}
  margin-bottom: ${Rem(20)};
  ${(props) => props.arrow && css`
    margin-bottom: 2rem;
    > span {
      transform: rotate(90deg);
    }
  `}
  
  @media (${BP.tablet}) {
    > span {
      transform: none;
    }
    margin-bottom: 0;
  }
`;
